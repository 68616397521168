<template>
  <div>
    <div class="header flex">
      <div class="logo">
        <a href="/" class="text-logo">PLAYERVERSE.GG</a>
      </div>
      <div v-if="isLoggedIn" class="nav-items flex">
        <div>
          <ul class="flex row mm-banner-items">
            <li>
              <router-link class="flex" :to="`/Profile/${user.id}`">
                <img :src="avatarUrl" alt="avatar" class="mm-banner-avatar" />
              </router-link>
            </li>
            <li class="flex center" v-for="(teammate, index) in partyMembers" :key="index">
              <div v-if="teammate" class="avatar-container">
                <router-link class="flex" :to="`/Profile/${teammate.id}`">
                  <img :src="teammate.avatar" alt="avatar" class="mm-banner-avatar" />
                </router-link>
                <button v-if="user.id == teammate.isOwner" class="header-remove-btn flex center"
                  @click="removeMember(teammate.id)">X</button>
              </div>
              <div class="teammateAdd" v-else>
                <button @click="openAddToPartyModal">+</button>
              </div>
            </li>
          </ul>
        </div>
        <div class="joinQueueButton flex">
          <button @click="toggleQueue" :class="isInQueue ? 'searching' : ''">
            {{ isInQueue ? 'Leave Queue' : 'Join Queue' }}
          </button>
        </div>
        <div class="display-elo">
          <p>
            PlayerVerse
            <span style="font-weight: 900" :style="{ color: formatedEloColor.player }">{{ formatedElo.player ?
              formatedElo.player : 0 }}</span>
          </p>
        </div>
        <div class="display-elo">
          <p>
            CS2
            <span style="font-weight: 900" :style="{ color: formatedEloColor.cs2 }">{{ formatedElo.cs2 ? formatedElo.cs2
              : 0 }}</span>
          </p>
        </div>
        <div class="nav-gamecoins flex">
          <img src="../assets/svg/gamecoins.svg" />
          <p style="font-weight: 900; font-style: italic;">{{ formatedGameCoins }}</p>
        </div>
        <div class="nav-profile flex">
          <div class="nav-profile-img">
            <router-link :to="`/Profile/${user.id}`">
              <img :src="avatarUrl" />
            </router-link>
          </div>
          <img class="settingsNav" src="../assets/svg/settings.svg" @click="toggleDropdown" />
          <div class="dropdown" v-show="showDropdown">
            <ul class="dropdown-content">
              <li class="flex row dropdownItem">
                <p>Site Status</p>
                <img :src="getStatusImage(status)" @click="toggleStatus" />
              </li>
              <li class="flex row dropdownItem">
                <p>Region</p>
                <p>{{ player.region ? player.region : 'Unset' }}</p>
              </li>
              <li class="flex row dropdownItem">
                <p>Team</p>
                <a :href="playerTeam != false ? '/team/' + player.team : null">{{ playerTeam != false ? playerTeam.name
                  : 'No Team' }}</a>
              </li>
              <li class="flex row dropdownItem">
                <p>Discord</p>
                <a v-if="player.discordId" :href="'https://discord.com/users/' + player.discordId"
                  target="_blank">Linked</a>
                <a v-else-if="player.discordId === null" @click="linkDiscord" target="_blank">Link Discord</a>
              </li>
              <li>
                <button @click="toggleDropdown">Close</button>
                <button class="btn-dropdown-close" v-if="isLoggedIn" @click="logout">Log out</button>
                <button class="btn-danger" @click="deleteAccount">Delete User</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="login-button" v-else>
        <button @click="login">Login via Steam</button>
      </div>

    </div>

    <div>
      <ul class="sidebar-nav-menu flex">
        <div>
          <router-link to="/">
            <li class="sidebar-menu-item">matchmaking</li>
          </router-link>
          <router-link to="/ladders">
            <li class="sidebar-menu-item">Team Leaderboards</li>
          </router-link>
          <router-link to="/leaderboards">
            <li class="sidebar-menu-item">Solo Leaderboards</li>
          </router-link>
          <router-link to="/wagers">
            <li class="sidebar-menu-item">Wager Matches</li>
          </router-link>
          <router-link to="/tournaments">
            <li class="sidebar-menu-item">Tournaments</li>
          </router-link>
        </div>

        <div>
          <router-link to="/premium">
            <li class="sidebar-menu-item premium">upgrade to premium</li>
          </router-link>
        </div>
      </ul>
    </div>

    <div>
      <ul class="sidebar-nav-menu-right flex">
        <h4>Notifications</h4>
        <li v-for="notification in sidebarNotifications" :key="notification._id" class="notification-bar-item">
          <div v-if="notification.type === 'friend-request'" class="flex row">
            <a :href="'/profile/' + notification.userId"><img :src="notification.avatar" class="chat-friend-avatar flex" /></a>
            <div class="flex column notificaiton-bar-content">
              <p><span style="font-weight: 900;">{{ notification.displayName }}</span> wants to be your friend</p>
              <div class="flex center">
                <button class="accept-btn-sidebar flex" @click="acceptFriend(notification._id)">
                  accept
                </button>
                <button class="reject-btn-sidebar flex" @click="rejectFriend(notification._id)">
                  decline
                </button>
              </div>
            </div>
          </div>

          <div v-if="notification.type === 'party-request'" class="flex row">
            <a :href="'/profile/' + notification.userId"><img :src="notification.avatar" class="chat-friend-avatar flex" /></a>
            <div class="flex column notificaiton-bar-content">
              <p><span style="font-weight: 900;">{{ notification.displayName }}</span> wants to a party</p>
              <div class="flex center">
                <button class="accept-btn-sidebar flex" @click="acceptInvite(notification._id)">
                  accept
                </button>
                <button class="reject-btn-sidebar flex" @click="rejectInvite(notification._id)">
                  decline
                </button>
              </div>
            </div>
          </div>

          <div v-if="notification.type === 'challenge-request'" class="flex row">
            {{ notification }}
            <div class="flex column notificaiton-bar-content">
              <p><span style="font-weight: 900;">{{ notification.displayName }}</span> has invited you to a party</p>
              <div class="flex center">
                <button class="accept-btn-sidebar flex" @click="acceptChallenge(notification._id)">
                  accept
                </button>
                <button class="reject-btn-sidebar flex" @click="rejectChallenge(notification._id)">
                  decline
                </button>
              </div>
            </div>
          </div>

          <div v-if="notification.type === 'match-update'" class="flex row">
            <div class="flex row notificaiton-bar-content">
              <p>Your match has started its Veto</p>
              <a class="accept-btn-sidebar flex" :href="'/match/' + notification.match.matchId">
                Join
              </a>
            </div>
          </div>

        </li>
      </ul>
    </div>

    <div class="chat-popup" v-show="showChat">
      <div class="chat-header">
        <button class="chat-close" @click="closeChat">x</button>
      </div>
      <div class="chat-content">
        <template v-if="!currentChatFriend">
          <p class="chat-heading">Add Friend</p>
          <div class="friend-search">
            <input type="text" v-model="searchQuery" placeholder="Search for friends" @input="searchUsers" />
            <div v-if="searchResults.length">
              <div v-for="user in searchResults" :key="user.steamId" class="chat-friend flex">
                <img :src="user.avatar" class="chat-friend-avatar" />
                <span>{{ user.displayName }}</span>
                <div class="flex center">
                  <button class="accept-btn flex" @click="addFriend(user.steamId)">
                    <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 149.1 112.08">
                      <polygon class="accept-btn-svg"
                        points="149.1 13.28 135.82 0 50.05 85.76 13.28 48.99 0 62.27 49.81 112.08 50.05 111.84 50.3 112.08 149.1 13.28" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="partyInvitations.length">
            <p class="chat-heading">Party Invitations</p>
            <div v-for="invitation in partyInvitations" :key="invitation._id" class="chat-friend flex">
              <img :src="invitation.avatar" class="chat-friend-avatar" />
              <span>{{ invitation.displayName }}</span>
              <div class="flex center">
                <button class="accept-btn flex" @click="acceptInvite(invitation._id)">
                  <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 149.1 112.08">
                    <polygon class="accept-btn-svg"
                      points="149.1 13.28 135.82 0 50.05 85.76 13.28 48.99 0 62.27 49.81 112.08 50.05 111.84 50.3 112.08 149.1 13.28" />
                  </svg>
                </button>
                <button class="reject-btn flex" @click="rejectInvite(invitation._id)">
                  <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 63.09 63.09">
                    <polygon class="reject-btn-svg"
                      points="63.09 13.28 49.81 0 31.55 18.26 13.28 0 0 13.28 18.26 31.55 0 49.81 13.28 63.09 31.55 44.83 49.81 63.09 63.09 49.81 44.83 31.55 63.09 13.28" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div v-if="challengeNotifications.length">
            <p class="chat-heading">Challenges</p>
            <div v-if="challengeNotifications.length">
              <div v-for="(notification, index) in challengeNotifications" :key="index" class="chat-friend flex">
                <a :href="`/team/` + notification.teamData?._id">
                  <img
                    :src="notification.teamData?.avatar ? notification.teamData?.avatar : require('@/assets/svg/profiles/default/blankplayer.png')"
                    class="chat-friend-avatar" />
                </a>
                <span><a :href="`/team/` + notification.teamData?._id">{{ notification.teamData?.name }}</a></span>
                <div class="flex center">
                  <button class="accept-btn flex" @click="acceptChallenge(notification._id)">
                    <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 149.1 112.08">
                      <polygon class="accept-btn-svg"
                        points="149.1 13.28 135.82 0 50.05 85.76 13.28 48.99 0 62.27 49.81 112.08 50.05 111.84 50.3 112.08 149.1 13.28" />
                    </svg>
                  </button>
                  <button class="reject-btn flex" @click="rejectChallenge(notification._id)">
                    <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 63.09 63.09">
                      <polygon class="reject-btn-svg"
                        points="63.09 13.28 49.81 0 31.55 18.26 13.28 0 0 13.28 18.26 31.55 0 49.81 13.28 63.09 31.55 44.83 49.81 63.09 63.09 49.81 44.83 31.55 63.09 13.28" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div v-if="teamInvite.length">
            <p class="chat-heading">Team Invites</p>
            <div v-if="teamInvite.length">
              <div v-for="(invite, index) in teamInvite" :key="index" class="chat-friend flex">
                <a :href="`/team/` + invite.teamData?._id">
                  <img
                    :src="invite.teamData?.avatar ? invite.teamData?.avatar : require('@/assets/svg/profiles/default/blankplayer.png')"
                    class="chat-friend-avatar" />
                </a>
                <span><a :href="`/team/` + invite.teamData?._id">{{ invite.teamData?.name }}</a></span>
                <div class="flex center">
                  <button class="accept-btn flex" @click="acceptTeamInvite(invite._id)">
                    <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 149.1 112.08">
                      <polygon class="accept-btn-svg"
                        points="149.1 13.28 135.82 0 50.05 85.76 13.28 48.99 0 62.27 49.81 112.08 50.05 111.84 50.3 112.08 149.1 13.28" />
                    </svg>
                  </button>
                  <button class="reject-btn flex" @click="declineTeamInvite(invite._id)">
                    <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 63.09 63.09">
                      <polygon class="reject-btn-svg"
                        points="63.09 13.28 49.81 0 31.55 18.26 13.28 0 0 13.28 18.26 31.55 0 49.81 13.28 63.09 31.55 44.83 49.81 63.09 63.09 49.81 44.83 31.55 63.09 13.28" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div v-if="friendRequests.length">
            <p class="chat-heading">Friend Requests</p>
            <div v-for="request in friendRequests" :key="request._id" class="flex chat-friend">
              <img :src="request.avatar" class="chat-friend-avatar" />
              <span>{{ request.displayName }}</span>
              <div class="flex center">
                <button class="accept-btn flex" @click="acceptFriend(request._id)">
                  <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 149.1 112.08">
                    <polygon class="accept-btn-svg"
                      points="149.1 13.28 135.82 0 50.05 85.76 13.28 48.99 0 62.27 49.81 112.08 50.05 111.84 50.3 112.08 149.1 13.28" />
                  </svg>
                </button>
                <button class="reject-btn flex" @click="rejectFriend(request._id)">
                  <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 63.09 63.09">
                    <polygon class="reject-btn-svg"
                      points="63.09 13.28 49.81 0 31.55 18.26 13.28 0 0 13.28 18.26 31.55 0 49.81 13.28 63.09 31.55 44.83 49.81 63.09 63.09 49.81 44.83 31.55 63.09 13.28" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <p class="chat-heading">Friends List</p>
          <div v-for="friend in sortedFriends" :key="friend.friendId" class="flex chat-friend">
            <a :href="'/Profile/' + friend.friendId"><img :src="friend.avatar" class="chat-friend-avatar" /></a>
            <a :href="'/Profile/' + friend.friendId" class="chat-link">{{ friend.displayName }}</a>
            <span v-if="hasUnreadMessages(friend.friendId)" class="unread-indicator">•</span>
            <div class="flex center">
              <a @click="openChatWithFriend(friend)"><img src="@/assets/svg/chat.svg" class="chat-friend-msg" /></a>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="chat-menu-btn flex center">
            <button class="chat-back" @click="backToFriendsList"><img src="../assets/svg/back.svg"></button>
            <div class="flex center">
              <a :href="'/Profile/' + currentChatFriend.friendId" class="flex"><img :src="currentChatFriend.avatar"
                  class="chat-friend-avatar" style="width: 2em; height: 2em; margin-right: 0.5em" /></a>
              <a class="chat-link" :href="'/Profile/' + currentChatFriend.friendId">{{ currentChatFriend.displayName
                }}</a>
            </div>
            <div></div>
          </div>
          <div v-if="loadingChat" class="flex center h-40">
            <div class="spinner"></div>
          </div>
          <div v-else class="chat-history flex column" ref="chatHistory">
            <div>
              <div v-for="(msg, index) in messages" :key="index" class="chat-message">
                <div :class="{
                  'chat-message-sender flex column': msg.senderId === user.id,
                  'chat-message-receiver flex column': msg.senderId !== user.id,
                }">
                  <div class="chat-message-timestamp flex">
                    <div class="chat-inside-name" v-if="msg.senderId !== user.id">
                      {{ currentChatFriend.displayName }}
                    </div>
                    {{ formatTimestamp(msg.timestamp) }}
                  </div>
                  <div class="flex" style="align-items: center">
                    <img v-if="msg.senderId !== user.id" :src="currentChatFriend.avatar"
                      class="chat-friend-avatar no-chat-style" /><span>{{ msg.message }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-input">
            <input type="text" class="chat-message-input" placeholder="Type a message..." v-model="chatMessage"
              @keyup.enter="sendMessage" />
            <button @click="sendMessage" class="flex center"><img src="../assets/svg/forward.svg"
                style="width: 1em; height: 1em" /></button>
          </div>
        </template>
      </div>
    </div>

    <button class="open-chat-btn flex" @click="openChat" v-show="!showChat">
      <img src="@/assets/svg/chat.svg" class="chat-friend-msg" />
      <span v-if="notifications.length" class="notification-badge">{{
        notifications.length
        }}</span>
    </button>

    <AddToPartyModal v-if="showAddToPartyModal" :showModal="showAddToPartyModal" @close="closeAddToPartyModal" />
    <MatchAcceptanceModal v-if="showMatchAcceptanceModal && matchId" :matchId="matchId" @close="closeModal"
      :acceptedCount="acceptedCount" @accept="acceptMatch" @toggleQueue="toggleQueue" :userId="user.id" />
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, nextTick, provide } from "vue"; // Add `watch` here
import { mapState, mapActions, useStore } from "vuex";
import { initializeSocket } from '@/socket';
import api from "@/api";
import AddToPartyModal from "@/components/AddToPartyModal.vue";
import MatchAcceptanceModal from "@/components/MatchAcceptModal.vue";
import router from '@/router';
import pingService from '@/pingService';

export default {
  name: "SiteMenu",
  components: {
    AddToPartyModal,
    MatchAcceptanceModal
  },
  setup() {
    const store = useStore();
    const isLoggedIn = ref(false);
    const user = ref({});
    const playerTeam = ref([]);
    const avatarUrl = ref("");
    const showDropdown = ref(false);
    const status = ref("");
    const formatedElo = ref({});
    const formatedEloColor = ref({});
    const showChat = ref(false);
    const chatMessage = ref("");
    const messages = ref([]);
    const recentMessages = ref([]);
    const loading = ref(true);
    const loadingChat = ref(true);
    const friends = ref([]);
    const friendRequests = ref([]);
    const searchQuery = ref("");
    const searchResults = ref([]);
    const currentChatFriend = ref(null);
    const showAddToPartyModal = ref(false);
    const partyInvitations = ref([]);
    const sidebarNotifications = ref([]);
    const notifications = ref([]);
    const notificationSound = new Audio(require("@/assets/audio/message.mp3"));
    const joinSound = new Audio(require("@/assets/audio/party.mp3"));
    const player = ref([]);
    const challengeNotifications = ref([]);
    const teamInvite = ref([]);
    const showMatchAcceptanceModal = ref(false);
    const matchId = ref(localStorage.getItem("matchId") || ""); // Load matchId from localStorage
    const acceptedCount = ref(0);
    const formatedGameCoins = ref("");
    const formatedPlayerElo = ref("");
    const socket = ref(null);
    const isInQueue = computed(() => store.getters.isInQueue);
    const partyMembers = computed(() => store.state.partyMembers);
    const isInParty = computed(() => partyMembers.value.some(member => member !== null));

    const linkDiscord = () => {
  const discordClientId = "1280334043984167044";
  const redirectUri = "https://api.playerverse.gg:5001/api/discord/callback";

  if (!discordClientId || !redirectUri) {
    console.error('Discord Client ID or Redirect URI is not defined');
    return;
  }

  // Redirect to Discord OAuth
  window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${discordClientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=identify`;
};

    const handleTimeout = () => {
      showMatchAcceptanceModal.value = false;
      isInQueue.value = false;
      localStorage.removeItem("matchId");
      // Additional logic to handle timeout, e.g., requeueing players or notifying the user
    };

    const joinQueue = async () => {
      try {
        socket.value.emit('joinQueue', { userId: user.value.id });
        await api.joinQueue();
        store.dispatch('updateQueueStatus', true); // Update Vuex state
      } catch (error) {
        console.error('Error joining queue:', error);
      }
    };

    const leaveQueue = async () => {
      try {
        socket.value.emit('leaveQueue', { userId: user.value.id });
        await api.leaveQueue();
        store.dispatch('updateQueueStatus', false); // Update Vuex state
      } catch (error) {
        console.error('Error leaving queue:', error);
      }
    };

    const toggleQueue = () => {
      if (isInQueue.value === true) {
        leaveQueue();
      } else {
        joinQueue();
      }
    };

    const playNotificationSound = () => {
      notificationSound.play();
    };

    const getTeamData = async (teamId) => {
      try {
        const response = await api.getTeamProfile(teamId);
        return response;
      } catch (error) {
        console.error("Error fetching team data:", error);
        return null;
      }
    };

    const playJoinSound = () => {
      joinSound.play();
    };

    const fetchPlayerData = async () => {
      try {
        const data = await api.getPlayerSummaries(user.value.id);
        avatarUrl.value = data.avatar;
        player.value = data;

        if (data.discordId != null) {
          player.value.discordId = data.discordId;
        } else {
          player.value.discordId = null;
        }
        if (data.team != null) {
          const playerTeamData = await api.getTeamProfile(data.team);
          playerTeam.value = playerTeamData;
        } else {
          playerTeam.value = false;
        }
        if (data.gamecoins != null) {
          formatedGameCoins.value = new Intl.NumberFormat().format(player.value.gamecoins);
         } else {
          formatedGameCoins.value = '0';
         }
        if (data.elo != null) { 
          formatedElo.value.player = new Intl.NumberFormat().format(player.value.elo);
          formatedEloColor.value.player = getColor(player.value.elo);
        } else {
          formatedPlayerElo.value = '0';
        }
        status.value = data.personastate;
      } catch (error) {
        console.error("Error fetching player data:", error);
      }
    };

    const fetchElo = async () => {
      try {
        const csgoStatsArray = await api.getCsgoStats(user.value.id);
        const csgoObject = csgoStatsArray;
        const rankId = csgoObject[2].rank_id;
        formatedElo.value.cs2 = new Intl.NumberFormat().format(rankId);
        formatedEloColor.value.cs2 = getColor(rankId);
      } catch (error) {
        console.error("Error fetching ELO:", error);
      }
    };

    const setOnlineStatus = async () => {
      try {
        await api.updateStatus(user.value.id, 1);
      } catch (error) {
        console.error("Error setting online status:", error);
      }
    };

    const login = async () => {
      try {
        await api.initiateSteamLogin();
      } catch (error) {
        console.error("Error initiating Steam login:", error);
      }
    };

    const getStatusImage = (status) => {
      if (status === 1) {
        return require("@/assets/svg/online.svg");
      } else if (status === 0) {
        return require("@/assets/svg/offline.svg");
      } else {
        return require("@/assets/svg/default.svg");
      }
    };

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const toggleStatus = async () => {
      try {
        status.value = status.value === 1 ? 0 : 1;
        await api.updateStatus(user.value.id, status.value);
      } catch (error) {
        console.error("Error updating status:", error);
      }
    };

    const logout = async () => {
      try {
        window.location.href = 'https://playerverse.gg';
        await api.logout();
      } catch (error) {
        console.error("Error logging out:", error);
      }
    };

    const deleteAccount = async () => {
      try {
        await api.deleteUser();
        window.location.href = 'https://playerverse.gg';
        await api.logout();
      } catch (error) {
        console.error("Error logging out:", error);
      }
    };

    const getColor = (rankId) => {
      if (rankId >= 0 && rankId <= 4999) {
        return "#bcc8da";
      } else if (rankId >= 5000 && rankId <= 9999) {
        return "#3a75c4";
      } else if (rankId >= 10000 && rankId <= 14999) {
        return "#1a4f8d";
      } else if (rankId >= 15000 && rankId <= 19999) {
        return "#6a33b9";
      } else if (rankId >= 20000 && rankId <= 24999) {
        return "#8a2be2";
      } else if (rankId >= 25000 && rankId <= 29999) {
        return "#d834eb";
      } else {
        return "#d834eb";
      }
    };

    const openChat = () => {
      showChat.value = true;
      nextTick(() => {
        const chatPopup = document.querySelector(".chat-popup");
        if (chatPopup) {
          chatPopup.classList.add("show");
        }
        setTimeout(() => {
          document.addEventListener("click", handleClickOutside);
          scrollToBottom();
        }, 0);
      });
    };

    const closeChat = () => {
      showChat.value = false;
      currentChatFriend.value = null;
      document.removeEventListener("click", handleClickOutside);
    };

    const sendMessage = async () => {
      if (!chatMessage.value.trim() || !currentChatFriend.value) return;
      try {
        const messageData = {
          senderId: user.value.id,
          receiverId: currentChatFriend.value.friendId,
          message: chatMessage.value,
          timestamp: new Date().toISOString(),
        };
        socket.value.emit("sendMessage", messageData);
        messages.value.push({
          senderId: user.value.id,
          message: chatMessage.value,
          timestamp: messageData.timestamp,
        });
        chatMessage.value = "";
        scrollToBottom();
      } catch (error) {
        console.error("Error sending message:", error);
      }
    };

    const fetchFriends = async () => {
      try {
        const friendsData = await api.getFriends();

        // Filter friends with status "accepted" (though this should already be done server-side)
        friends.value = friendsData.filter((f) => f.status === "accepted");

        // Filter pending friend requests
        friendRequests.value = friendsData.filter((f) => f.status === "pending");

        sortFriends(); // Sort the friends list as needed
      } catch (error) {
        console.error("Error fetching friends:", error);
      }
    };

    const fetchFriendRequests = async () => {
      try {
        const response = await api.getFriendRequests();
        for (let request of response) {
          const user = await api.getPlayerSummaries(request.userId);
          request.displayName = user.displayName;
          request.avatar = user.avatar;
        }
        friendRequests.value = response;
        const enriched = response.map(r => ({
          ...r,
          type: "friend-request"
        }));

        sidebarNotifications.value.push(...enriched);
      } catch (error) {
        console.error("Error fetching friend requests:", error);
      }
    };

    const getPlayerTeamInvite = async () => {
      try {
        const response = await api.getPlayerTeamInvite(user.value.id);
        console.log(response)
        for (let request of response) {
          const teamData = await getTeamData(request.teamId);
          request.teamData = teamData;
          teamInvite.value.push(request);
          const enriched = request.map(r => ({
          ...r,
          type: "team-request"
        }));

        sidebarNotifications.value.push(...enriched);
        }
      } catch (error) {
        console.error("Error fetching challenge requests:", error);
      }
    };

    const acceptTeamInvite = async (inviteId) => {
  try {
    await api.acceptTeamInvite(inviteId); // Send accept request to the backend
    // Optionally, refresh the team invites after accepting
    await getPlayerTeamInvite();
  } catch (error) {
    console.error("Error accepting team invite:", error);
  }
};

const declineTeamInvite = async (inviteId) => {
  try {
    await api.declineTeamInvite(inviteId); // Send decline request to the backend
    // Optionally, refresh the team invites after declining
    await getPlayerTeamInvite();
  } catch (error) {
    console.error("Error declining team invite:", error);
  }
};

    const fetchChallengeRequests = async (steamId) => {
      try {
        const response = await api.getChallengeRequests(steamId);
        for (let request of response) {
          const teamData = await getTeamData(request.challengerId);
          request.teamData = teamData;
          challengeNotifications.value.push(request);
          const enriched = request.map(r => ({
          ...r,
          type: "challenge-request"
        }));

        sidebarNotifications.value.push(...enriched);
        }
      } catch (error) {
        console.error("Error fetching challenge requests:", error);
      }
    };

    const fetchPartyInvites = async () => {
  try {
    // Fetch all party invitations
    const invitations = await api.getPartyInvitations();

    // Filter only pending invitations
    const pendingInvitations = invitations.filter(request => request.status === 'pending');

    // Fetch additional information for each pending invitation
    for (let request of pendingInvitations) {
      const user = await api.getPlayerSummaries(request.inviterId);
      request.displayName = user.displayName;
      request.avatar = user.avatar;
    }

    // Update partyInvitations with the pending ones
    partyInvitations.value = pendingInvitations;
    const enriched = pendingInvitations.map(r => ({
          ...r,
          type: "party-request"
        }));

        sidebarNotifications.value.push(...enriched);

  } catch (error) {
    console.error("Error fetching party invites requests:", error);
  }
};


    const fetchRecentMessages = async () => {
      try {
        const recentMessagesData = await api.getRecentMessages(user.value.id);
        const filteredMessages = recentMessagesData.filter((msg) => {
          const timeDiff = (new Date() - new Date(msg.timestamp)) / 3600000;
          return timeDiff <= 12;
        });
        recentMessages.value = filteredMessages;
        loading.value = false;
      } catch (error) {
        console.error("Error fetching recent messages:", error);
      }
    };

    const fetchUnreadMessages = async () => {
      try {
        const response = await api.getUnreadMessages();
        notifications.value = response;
      } catch (error) {
        console.error("Error fetching unread messages:", error);
      }
    };

    const openChatWithFriend = async (friend) => {
      currentChatFriend.value = friend;
      loadingChat.value = true; // Ensure loading is set to true
      await api.markMessagesAsRead(friend.friendId);
      clearNotification(friend.friendId);
      await fetchChatHistory(friend.friendId); // Wait for chat history to be fetched
    };

    const fetchChatHistory = async (friendId) => {
      try {
        loadingChat.value = true; // Set loading to true before fetching
        const chatHistory = await api.getChatHistory(friendId);
        messages.value = chatHistory;
        scrollToBottom();
        setTimeout(() => {
          loadingChat.value = false; // Set loading to false after a 3-second delay
        }, 150000);
      } catch (error) {
        console.error("Error fetching chat history:", error);
        loadingChat.value = false; // Set loading to false if there's an error
      }
    };


    const backToFriendsList = () => {
      currentChatFriend.value = null;
      messages.value = [];
    };

    const hasUnreadMessages = (friendId) => {
      return notifications.value.some(
        (notification) => notification.senderId === friendId
      );
    };

    const sortFriends = () => {
      friends.value = friends.value.sort((a, b) => b.status - a.status);
    };

    const searchUsers = async () => {
      if (searchQuery.value.trim() === "") {
        searchResults.value = [];
        return;
      }
      try {
        const response = await api.searchUsers(searchQuery.value);
        searchResults.value = response;
      } catch (error) {
        console.error("Error searching users:", error);
      }
    };

    const addFriend = async (friendId) => {
      try {
        await api.addFriend(friendId);
        await fetchFriends();
        await fetchFriendRequests();
        searchResults.value = [];
        searchQuery.value = "";
      } catch (error) {
        console.error("Error adding friend:", error);
      }
    };

    const acceptFriend = async (requestId) => {
      try {
        await api.acceptFriend(requestId);
        await fetchFriends();
        await fetchFriendRequests();
      } catch (error) {
        console.error("Error accepting friend request:", error);
      }
    };

    const rejectFriend = async (requestId) => {
      try {
        await api.rejectFriend(requestId);
        await fetchFriends();
        await fetchFriendRequests();
      } catch (error) {
        console.error("Error rejecting friend request:", error);
      }
    };

    const acceptInvite = async (requestId) => {
      try {
        await api.acceptPartyInvitation(requestId);
        await fetchPartyInvites();
      } catch (error) {
        console.error("Error accepting party request:", error);
      }
    };

    const rejectInvite = async (requestId) => {
      try {
        await api.rejectPartyInvitation(requestId);
        await fetchPartyInvites();
      } catch (error) {
        console.error("Error rejecting party request:", error);
      }
    };

    const acceptChallenge = async (challengeId) => {
      try {
        await api.acceptChallenge(challengeId);
        await fetchChallengeRequests(user.value.id);
      } catch (error) {
        console.error("Error accepting challenge:", error);
      }
    };

    const rejectChallenge = async (challengeId) => {
      try {
        await api.rejectChallenge(challengeId);
        await fetchChallengeRequests(user.value.id);
      } catch (error) {
        console.error("Error rejecting challenge:", error);
      }
    };

    const sortedFriends = computed(() => friends.value);
    const uniqueRecentChatUsers = computed(() => {
      const now = new Date();
      const usersSet = new Set();
      const usersArray = [];
      recentMessages.value.forEach((msg) => {
        const messageTime = new Date(msg.timestamp);
        const diffHours = (now - messageTime) / (1000 * 60 * 60);
        if (diffHours <= 12) {
          const user = friends.value.find(
            (f) => f.steamId === msg.senderId || f.steamId === msg.receiverId
          );
          if (user && !usersSet.has(user.steamId)) {
            usersSet.add(user.steamId);
            usersArray.push(user);
          }
        }
      });
      return usersArray;
    });

    const addNotification = (data) => {
      notifications.value.push(data);
    };

    const clearNotification = (friendId) => {
      notifications.value = notifications.value.filter(
        (notification) => notification.senderId !== friendId
      );

      sidebarNotifications.value = notifications.value.filter(
        (notification) => notification.senderId !== friendId
      );
      
    };

    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(".dropdown");
      const settingsNav = document.querySelector(".settingsNav");
      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        settingsNav &&
        !settingsNav.contains(event.target)
      ) {
        showDropdown.value = false;
        document.removeEventListener("click", handleClickOutside);
      }
    };

    const scrollToBottom = () => {
      nextTick(() => {
        const chatHistory = document.querySelector(".chat-content");
        if (chatHistory) {
          setTimeout(() => {
            chatHistory.scrollTop = chatHistory.scrollHeight;
          }, 10);
        }
      });
    };

    const acceptMatch = () => {
      socket.value.emit('acceptMatch', { userId: this.user.id, matchId: this.matchId });
    };

    const formatTimestamp = (timestamp) => {
      const date = new Date(timestamp);
      const now = new Date();
      const isToday = date.toDateString() === now.toDateString();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return isToday ? `${hours}:${minutes}` : date.toDateString();
    };

    onMounted(async () => {
      try {
        const response = await api.checkLoginStatus();
        user.value = response.user;
        socket.value = initializeSocket(user.value.id);
        provide('socket', socket);
        isLoggedIn.value = response.isLoggedIn;
        if (user.value) {
          await fetchPlayerData();
          await fetchElo();
          await setOnlineStatus();
          await fetchFriends();
          await fetchFriendRequests();
          await fetchChallengeRequests(user.value.id);
          await getPlayerTeamInvite(user.value.id);
          await fetchRecentMessages();
          await fetchPartyInvites();
          await fetchUnreadMessages();
          store.dispatch('checkIfInParty');  // Call checkIfInParty on mount
          store.dispatch('fetchFriends');
        }

      socket.value.on("receiveMessage", (data) => {
        if (
          currentChatFriend.value &&
          data.senderId === currentChatFriend.value.friendId
        ) {
          messages.value.push(data);
          nextTick(scrollToBottom);
        } else {
          addNotification(data);
        }
        fetchUnreadMessages();
        playNotificationSound();
      });

      socket.value.on('receivePartyInvitation', async (data) => {
        await fetchPartyInvites();
        playNotificationSound();
        addNotification(data);
        const enriched = data.map(r => ({
          ...r,
          type: "party-request"
        }));

        sidebarNotifications.value.push(...enriched);
      });

      socket.value.on('challengeNotification', async (data) => {
        const teamData = await getTeamData(data.challengerId);
        if (teamData) {
          data.teamData = teamData;
          challengeNotifications.value.push(data);
          const enriched = data.map(r => ({
          ...r,
          type: "challenge-request"
        }));

        sidebarNotifications.value.push(...enriched);
        }
        playNotificationSound();
      });

      socket.value.on('challengeAccepted', async (matchId) => {
        // Navigate to the match page
        router.push({ name: 'MatchDetails', params: { matchId } }).catch(() => {
          // Force reload if there's an issue (optional)
          location.reload();
          playNotificationSound();
        });
      });

      socket.value.on('rejectChallenge', async (data) => {
        challengeNotifications.value = challengeNotifications.value.filter(notification => notification._id !== data.challengeId);
        sidebarNotifications.value = sidebarNotifications.value.filter(notification => notification._id !== data.challengeId)
        console.log('Challenge Declined');
      });

      socket.value.on('acceptPartyInvitation', async () => {
        playJoinSound();
        store.dispatch('checkIfInParty');
        clearNotification();
      });

      socket.value.on('rejectPartyInvitation', async () => {
        clearNotification();

      });

      socket.value.on('leaveParty', async () => {
        store.dispatch('checkIfInParty');
      });

      socket.value.on('joinParty', () => {
        store.dispatch('checkIfInParty');
      });

      socket.value.on('memberRemoved', () => {
        store.dispatch('checkIfInParty');
      });

      socket.value.on('QueueSearch', () => store.dispatch('checkQueueStatus'));

      socket.value.on('newMatch', (data) => {
        console.log('new match')
        if (data.match && data.match._id) {
          matchId.value = data.match._id;
          localStorage.setItem("matchId", matchId.value); // Save matchId to localStorage
          acceptedCount.value = data.acceptedCount;
          showMatchAcceptanceModal.value = true;
        } else {
          console.error('Received malformed match data:', data);
        }
      });

      socket.value.on('acceptanceUpdate', (data) => {
        if (data.matchId && data.matchId === matchId.value) {
          acceptedCount.value = data.acceptedCount;
        }
      });

      socket.value.on('matchStarted', () => {
        window.location.href = `/match/${matchId.value}`;
      });

      socket.value.on('clearMatchStorage', (data) => {
        if (data.matchId && data.matchId === matchId.value) {
          localStorage.removeItem("matchId");
          showMatchAcceptanceModal.value = false;
        }
      });

      socket.value.on("vetoStart", ({ matchId }) => {
    sidebarNotifications.value.push({
      type: "vetoStart",
      data: { matchId },
    });
  });

      // Check for existing match notification on load
      if (isLoggedIn.value) {
        socket.value.emit('checkForMatch', user.value.id);
      }

      if (localStorage.getItem("matchId")) {
        matchId.value = localStorage.getItem("matchId");
        showMatchAcceptanceModal.value = true;
      }

       // Call pingService (no need to pass playerIp)
    try {
      await pingService(user.value.id); // Only pass playerId
    } catch (pingError) {
      console.error('Ping Service Error:', pingError);
    }

      } catch (error) {
        console.log(error);
      }

    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
      socket.value.off("receiveMessage");
      socket.value.off("receivePartyInvitation");
      socket.value.off("acceptPartyInvitation");
      socket.value.off("leaveParty");
      socket.value.off("matchStarted");
      socket.value.off('newMatch');
      socket.value.off('acceptanceUpdate');
      socket.value.off('matchScheduled');
    });

    window.addEventListener("beforeunload", async () => {
      if (isLoggedIn.value) {
        try {
          await api.updateStatus(user.value.id, 0);
        } catch (error) {
          console.error("Error setting offline status:", error);
        }
      }
    });

    const leaveParty = () => {
      store.dispatch('leaveParty');
    };

    return {
      linkDiscord,
      acceptMatch,
      showMatchAcceptanceModal,
      matchId,
      acceptedCount,
      handleTimeout,
      isLoggedIn,
      user,
      avatarUrl,
      player,
      showDropdown,
      status,
      formatedElo,
      formatedEloColor,
      showChat,
      chatMessage,
      messages,
      friends,
      friendRequests,
      partyInvitations,
      sidebarNotifications,
      searchQuery,
      searchResults,
      currentChatFriend,
      fetchPlayerData,
      fetchElo,
      setOnlineStatus,
      login,
      getStatusImage,
      toggleDropdown,
      toggleStatus,
      logout,
      deleteAccount,
      getColor,
      openChat,
      closeChat,
      sendMessage,
      fetchFriends,
      fetchFriendRequests,
      fetchRecentMessages,
      openChatWithFriend,
      backToFriendsList,
      searchUsers,
      addFriend,
      acceptFriend,
      rejectFriend,
      acceptInvite,
      rejectInvite,
      sortedFriends,
      uniqueRecentChatUsers,
      formatTimestamp,
      scrollToBottom,
      notifications,
      fetchPartyInvites,
      hasUnreadMessages,
      showAddToPartyModal,
      challengeNotifications,
      fetchChallengeRequests,
      acceptChallenge,
      rejectChallenge,
      loading: true,
      playerTeam,
      formatedGameCoins,
      formatedPlayerElo,
      toggleQueue,
      isInQueue,
      socket,
      partyMembers,
      isInParty,
      leaveParty,
      declineTeamInvite,
      acceptTeamInvite,
      teamInvite
    };
  },
  computed: {
    ...mapState(['user', 'teammates', 'searchingForMatch', 'queueCount', 'formatedElo']),
  },
  methods: {
    openAddToPartyModal() {
      this.showAddToPartyModal = true;
    },
    closeAddToPartyModal() {
      this.showAddToPartyModal = false;
    },
    closeModal() {
      this.showMatchAcceptanceModal = false;
    },
    ...mapActions(['checkLoginStatus', 'fetchPlayerData', 'fetchElo', 'fetchQueueCount', 'toggleSearch', 'removePartyMember', 'leaveParty']),
    async removeMember(memberId) {
      await this.removePartyMember(memberId);
    },
    goToMatchPage(matchId) {
      this.$router.push(`/match/${matchId}`);
    }
  },
  watch: {
    showMatchAcceptanceModal(newVal) {
      if (!newVal) {
        localStorage.removeItem("matchId");
      }
    }
  }
};
</script>

<style scoped>
.btn-danger {
  margin-top: 0.5em;
}

.notification-bar-item {
  padding: 1em;
  border-bottom: solid 0.02em #1e1e1e;
  border-left: solid 0.1em transparent;
  transition: all ease 0.2s;
}

.notification-bar-item:hover {
  border-left: solid 0.1em var(--accent);
  transition: all ease 0.2s;
}

.notificaiton-bar-content {
  align-items: flex-start;
  margin-left: 1em;
}

.accept-btn-sidebar, .reject-btn-sidebar {
  border-radius: 0.2em;
  border: solid 0.02em var(--accent);
  background-color: transparent;
  transition: all ease 0.2s;
  margin: 0 0.5em;
}

.accept-btn-sidebar:hover, .reject-btn-sidebar:hover {
  border: solid 0.02em var(--accent);
  background-color: var(--accent);
  color: white;
  transition: all ease 0.2s;
  cursor: pointer;
}

</style>