<template>
  <div>
    <div v-if="loading" class="flex center h-70">
      <div class="spinner"></div>
    </div>
    <div v-else>
      <div>
        <div class="sticky-header">
          <div>
            <ul class="page-tab-menu flex">
              <li v-for="region in regions" :key="region" :class="{ 'tab-active': selectedTab === region }" @click="selectTab(region)">
                {{ region }}
              </li>
            </ul>
          </div>
          <div class="border-bottom profile-detailed flex" :style="{ backgroundImage: `url(${defaultProfile.background})`,
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
                backgroundBlendMode: 'overlay', // Blend the color with the image
                height: '15vh'
            }">
            <div class="profile-detailed-overlay"></div>
            <div class="profile-avatar" v-if="topTeam" style="top:0">
              <a :href="'team/' + topTeam._id"><img :src="topTeam.avatar || defaultProfile.avatar" /></a>
            </div>
            <ul class="flex column">
              <div class="flex column" v-if="topTeam">
                <p style="margin-bottom: -0.5em" class="subheading-text">Top Team</p>
                <p class="heading-text"><a :href="'team/' + topTeam._id">{{ topTeam.name }}</a></p>
                <span class="flex top-team">
                  <img src="@/assets/svg/qualipoints.svg" />
                  <p>{{ topTeam.qualipoints }}</p>
                </span>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="profile-stats flex column">
        <div class="page-tabs container">
          <div v-for="region in regions" :key="region">
            <div v-if="selectedTab === region" class="flex column">
              <div class="flex right marg-topbot-1" v-if="!isInTeam">
                <button v-if="user.region === region" class="btn-20" @click="showCreateTeamModal(region)">Create Team</button>
                <button v-else class="btn-20 btn-disabled">Incorrect Region</button>
              </div>
              <ul class="flex table column ladder-table" v-if="filteredTeams != false">
                <div class="table-headings flex">
                  <div class="table-heading" v-for="heading in tableHeadings" :key="heading">{{ heading }}</div>
                </div>
                <div class="table-content flex" v-for="(team, index) in filteredTeams" :key="team._id">
                  <div>{{ index + 1 }}</div>
                  <div><a class="ladder-link" :href="'/team/' + team._id">{{ team.name}}</a></div>
                  <div>{{ team.wins || 0 }}</div>
                  <div>{{ team.losses || 0 }}</div>
                  <div>{{ team.wins ? ((team.wins / (team.wins + team.losses)) * 1000).toFixed(0) : 0 }}%</div>
                  <div>{{ team.qualipoints || 0 }}</div>
                  <div v-if="!isUserInTeam(team) && isInTeam">
                    <button v-if="hasMatch" @click="goToMatchPage(hasMatch)" class="btn-green">Match Page</button>
                    <button v-else-if="challengeStatus.status === 'pending'" class="btn-disabled">Challenged</button>
                    <button v-else @click="challengeTeam(team._id)">Challenge</button>
                  </div>
                  <div v-else></div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <EditTeamModal v-if="showModal" :isVisible="showModal" :editableTeam="editableTeam" @save="createTeam" @close="closeModal" />
    </div>
  </div>
</template>

<script>
import EditTeamModal from "@/components/editFieldModal.vue";
import api from "../api";
import { mapState, mapActions } from "vuex";

export default {
  name: "GameLadders",
  components: {
    EditTeamModal,
  },
  data() {
    return {
      selectedTab: "Oceania",
      regions: ["Oceania", "North America", "South America", "Europe", "Asia", "Africa"],
      teams: [],
      defaultProfile: { avatar: require('@/assets/svg/profiles/default/blankplayer.png'), background: require('@/assets/svg/profiles/default/background.svg') },
      showModal: false,
      editableTeam: { name: "", avatar: "", socials: [] },
      loading: true,
      challengeStatus: {},
      hasMatch: '',
      user: {}
    };
  },
  computed: {
    ...mapState({
      user: state => state.user, // Access user from Vuex
      queueCount: state => state.queueCount, // Access queue count
    }),
    filteredTeams() {
      if (this.teams != null) {
        return this.teams.filter((team) => team.region === this.selectedTab).sort((a, b) => b.qualipoints - a.qualipoints);
      } else {
        return false;
      }
    },
    isInTeam() {
      return this.teams.some((team) => team.players.includes(this.user.steamId));
    },
    topTeam() {
      return this.filteredTeams.length > 0 ? this.filteredTeams[0] : null;
    },
    tableHeadings() {
      return ["Place", "Name", "W", "L", "W/L %", "Points", "Challenge"];
    }
  },
  methods: {
    ...mapActions(['fetchPlayerData', 'fetchQueueCount', 'toggleSearch']),
    isUserInTeam(team) {
      return team.players.includes(this.user.steamId);
    },
    async fetchData() {
      try {
        this.loading = true;

        const [teams, loginStatus, matches] = await Promise.all([
          api.getAllTeams(),
          api.checkLoginStatus(),
          api.getMatches()
        ]);

        this.teams = teams;
        this.user = await api.getPlayerSummaries(loginStatus.user.id);
        const challenges = await api.getSentChallenges(this.user.steamId);

        console.log(challenges);

        challenges.forEach(challenge => {
          if (challenge.type === "challenge" && (challenge.challengerId === this.user.team || challenge.teamId === this.user.team)) {
            
            if (challenge.status === 'accepted') {
              // Find the battle match that is associated with this specific challenge
              const battleMatch = matches.find(match => match.type === "challenge" && (challenge.challengerId === this.user.team || challenge.teamId === this.user.team));
              if (battleMatch) {
                this.hasMatch = battleMatch._id;
              }
            } else if (challenge.status === 'pending') {
              this.challengeStatus.status = 'pending'
            }
          }
        });

        this.loading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false;
      }
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    showCreateTeamModal(region) {
      this.editableTeam = { name: "", avatar: this.defaultProfile.avatar, banner: this.defaultProfile.background, socials: [{}], region: region };
      this.showModal = true;
    },
    async createTeam(team) {
      try {
        await api.createTeam(team);
        await this.fetchData();
        this.showModal = false;
      } catch (error) {
        console.log("Error creating team:", error);
      }
    },
    closeModal() {
      this.showModal = false;
    },
    async challengeTeam(opponentTeamId) {
      const myTeam = await api.getTeamProfile(this.user.team);
      const opponentTeam = await api.getTeamProfile(opponentTeamId);
      const rankDifference = Math.abs(myTeam.qualipoints - opponentTeam.qualipoints);

      if (rankDifference > 1000) {
        alert("You can only challenge teams within 1000 qualipoints.");
        return;
      }

      try {
        await api.createChallenge(this.user.team, opponentTeamId);
        await this.fetchData();
      } catch (error) {
        console.error("Error challenging team", error);
      }
    },
    goToMatchPage(matchId) {
      this.$router.push(`/match/${matchId}`);
    },
    async initPage() {
      if (!this.user.id) {
        await this.fetchPlayerData(); // Fetch user data if not loaded
      }
      await this.fetchQueueCount(); // Fetch queue count on load
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
/* Your existing styles here */
</style>