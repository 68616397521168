<template>
    <div>
        <div v-if="loading == true" class="flex center h-70">
            <div class="spinner"></div>
        </div>
        <div v-if="loading == false">
            <div class="sticky-header">
                <div>
                    <ul class="page-tab-menu flex">
                        <li :class="{ 'tab-active': selectedTab === 'Global' }" @click="selectTab('Global')">Global</li>
                        <li :class="{ 'tab-active': selectedTab === 'Oceania' }" @click="selectTab('Oceania')">Oceania
                        </li>
                        <li :class="{ 'tab-active': selectedTab === 'North America' }"
                            @click="selectTab('North America')">
                            North America
                        </li>
                        <li :class="{ 'tab-active': selectedTab === 'South America' }"
                            @click="selectTab('South America')">
                            South America
                        </li>
                        <li :class="{ 'tab-active': selectedTab === 'Europe' }" @click="selectTab('Europe')">Europe</li>
                        <li :class="{ 'tab-active': selectedTab === 'Asia' }" @click="selectTab('Asia')">Asia</li>
                        <li :class="{ 'tab-active': selectedTab === 'Africa' }" @click="selectTab('Africa')">Africa</li>
                    </ul>
                </div>
                <div class="border-bottom profile-detailed flex" :style="{ backgroundImage: `url(${defaultProfile.background})`,
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
                backgroundBlendMode: 'overlay' // Blend the color with the image
            }">
                    <div class="profile-detailed-overlay"></div>
                    <ul class="flex column">

                    </ul>
                </div>
            </div>
            <div class="profile-stats flex column">
                <div class="page-tabs container">
                    <div class="flex column">
                        <ul class="flex table column ladder-table">
                            <div class="table-headings flex">
                                <div>
                                    <div class="table-heading">Place</div>
                                </div>
                                <div>
                                    <div class="table-heading">Name</div>
                                </div>
                                <div>
                                    <div class="table-heading">W</div>
                                </div>
                                <div>
                                    <div class="table-heading">L</div>
                                </div>
                                <div>
                                    <div class="table-heading">W/L %</div>
                                </div>
                                <div>
                                    <div class="table-heading">Points</div>
                                </div>
                                <div v-if="selectedTab === 'Global'">
                                    <div class="table-heading">Region</div>
                                </div>
                            </div>

                            <!-- Top 5 Sticky -->
                            <div class="table-content flex top-five-row" v-for="(user, index) in topFive"
                                :key="'top' + user.id">
                                <div>{{ index + 1 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ?? 0 }}</div>
                                <div>{{ user.losses ?? 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ?? 0 }}</div>
                                <div v-if="selectedTab === 'Global'">{{ user.region }}</div>
                            </div>

                            <!-- Paginated Below -->
                            <div class="table-content flex" v-for="(user, index) in paginatedUsers"
                                :key="'page' + user.id">
                                <div>{{ index + 6 }}</div>
                                <div><a class="ladder-link" :href="'/Profile/' + user.steamId">{{ user.displayName
                                        }}</a></div>
                                <div>{{ user.wins ?? 0 }}</div>
                                <div>{{ user.losses ?? 0 }}</div>
                                <div>{{ user.wins ? ((user.wins / (user.wins + user.losses)) * 1000).toFixed(0) : 0 }}%
                                </div>
                                <div>{{ user.gamecoins ?? 0 }}</div>
                                <div v-if="selectedTab === 'Global'">{{ user.region }}</div>
                            </div>
                        </ul>

                        <!-- Pagination -->
                        <div class="pagination">
                            <button v-for="n in totalPages" :key="n" @click="changePage(n)"
                                :class="{ active: page === n }">{{ n }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "../api";

export default {
  name: "UserLeaderboard",
  data() {
    return {
      selectedTab: "Global",
      defaultProfile: {
        background: require("@/assets/svg/profiles/default/background.svg"),
      },
      users: [],
      loading: true,
      page: 1,
      pageSize: 15,
    };
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
      this.page = 1;
    },
    async fetchData() {
      try {
        this.loading = true;
        this.users = await api.getAllUsers();
        this.loading = false;
      } catch (error) {
        console.log("Error fetching users: " + error);
        this.loading = false;
      }
    },
    changePage(page) {
      this.page = page;
    },
  },
  computed: {
      filteredUsers() {
          const filtered = this.selectedTab === "Global"
              ? [...this.users] // clone to avoid mutating original
              : this.users.filter(user => user.region === this.selectedTab);

          return filtered.sort((a, b) => b.gamecoins - a.gamecoins);
      },
    topFive() {
      return this.filteredUsers.slice(0, 5);
    },
    paginatedUsers() {
      const start = 5 + (this.page - 1) * this.pageSize;
      return this.filteredUsers.slice(start, start + this.pageSize);
    },
    totalPages() {
      return Math.ceil((this.filteredUsers.length - 5) / this.pageSize);
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style scoped>
.page-tabs {
    margin-top: 25vh;
}
</style>