<template>
    <div class="container">
        <div v-if="error" class="error">{{ error }}</div>
        <div v-else-if="loading" class="flex center h-70">
            <div class="spinner"></div>
        </div>
        <div v-show="!loading">
            <h1>Ban Bot</h1>
            <div v-if="loading"></div>
            <div v-else>
                <!-- Stats Table -->
                <h3>Ban Statistics</h3>
                <div class="flex">
                    <div class="tourn-detailed flex column">Accounts Searched <span class="accent">{{ stats.totalEntries
                            }}</span></div>
                </div>
                <div class="flex">
                    <div class="tourn-detailed flex column">VAC Ban <span class="accent">{{ percentages.vacBanPercentage
                            }}%</span></div>
                    <div class="tourn-detailed flex column">Economy Ban <span class="accent">{{
                            percentages.econBanPercentage }}%</span></div>
                    <div class="tourn-detailed flex column">Community Ban <span class="accent">{{
                            percentages.communityBanPercentage }}%</span></div>
                    <div class="tourn-detailed flex column">Game Ban <span class="accent">{{
                            percentages.gameBanPercentage }}%</span></div>
                </div>
                <div class="flex">
                    <div class="tourn-detailed flex column">VAC Bans <span class="accent">{{ stats.totalVacBans
                            }}</span></div>
                    <div class="tourn-detailed flex column">Economy Bans <span class="accent">{{ stats.totalEconBans
                            }}</span></div>
                    <div class="tourn-detailed flex column">Community Bans <span class="accent">{{
                            stats.totalCommunityBans }}</span></div>
                    <div class="tourn-detailed flex column">Game Bans <span class="accent">{{ stats.totalGameBans
                            }}</span></div>
                </div>
            </div>
            <!-- Line Graph -->
            <div>
                <h3>Vac Bans Over the Last 12 Months</h3>
                <canvas ref="banGraph" class="stat-graph"></canvas>
            </div>
            <div v-if="loading"></div>
            <div v-else>
                <!-- Paginated List -->
                <h3>All Ban Entries</h3>
                <!-- Search Bar -->
                <div class="search-bar">
                    <input type="text" v-model="searchQuery" placeholder="Search Steam ID" @input="filterTable"
                        class="search-input" />
                </div>
                <ul class="flex table column stat-table">
                    <div class="table-headings flex">
                        <div class="table-heading">
                            Steam ID
                        </div>
                        <div class="table-heading filter" @click="sortTable('numberOfVACBans')">
                            VAC Bans
                            <span>{{ getSortIndicator('numberOfVACBans') }}</span>
                        </div>
                        <div class="table-heading filter" @click="sortTable('communityBanned')">
                            Community Banned
                            <span>{{ getSortIndicator('communityBanned') }}</span>
                        </div>
                        <div class="table-heading filter" @click="sortTable('gameBans')">
                            Game Bans
                            <span>{{ getSortIndicator('gameBans') }}</span>
                        </div>
                        <div class="table-heading filter" @click="sortTable('estimatedBanDate')">
                            Estimated Ban Date
                            <span>{{ getSortIndicator('estimatedBanDate') }}</span>
                        </div>
                        <div class="table-heading filter" @click="sortTable('dateFetched')">
                            Date Fetched
                            <span>{{ getSortIndicator('dateFetched') }}</span>
                        </div>
                    </div>
                    <div v-for="entry in paginatedEntries" :key="entry.steamid" class="table-content flex">
                        <div>
                            <a :href="'https://steamcommunity.com/profiles/' + entry.steamid" target="_blank">
                                {{ entry.steamid }}
                            </a>
                        </div>
                        <div>{{ entry.numberOfVACBans }}</div>
                        <div>{{ entry.communityBanned ? "Yes" : "No" }}</div>
                        <div>{{ entry.gameBans }}</div>
                        <div>{{ formatDate(entry.estimatedBanDate) }}</div>
                        <div>{{ formatDate(entry.dateFetched) }}</div>
                    </div>
                </ul>

                <!-- Pagination Controls -->
                <div class="pagination">
                    <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
                    <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
                    <span>Page {{ currentPage }} of {{ totalPages }}</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, nextTick } from "vue";
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import api from "../api";

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, annotationPlugin);

export default {
    name: "BanStatistics",
    setup() {
        const stats = ref(null);
        const percentages = ref({
            vacBanPercentage: 0,
            econBanPercentage: 0,
            communityBanPercentage: 0,
            gameBanPercentage: 0,
        });
        const loading = ref(true);
        const error = ref(null);
        const allEntries = ref([]);
        const filteredEntries = ref([]);
        const searchQuery = ref("");
        const currentPage = ref(1);
        const itemsPerPage = 20;
        const banGraph = ref(null); // Ref for the canvas element
        const sortKey = ref("");
        const sortDirection = ref("asc");

        const fetchBanStats = async () => {
            try {
                const data = await api.getBanData();
                stats.value = data;
                allEntries.value = data.banDetails;
                filteredEntries.value = allEntries.value;

                // Compute percentages
                if (stats.value.totalEntries > 0) {
                    percentages.value.vacBanPercentage = (
                        (stats.value.totalVacBans / stats.value.totalEntries) *
                        100
                    ).toFixed(2);
                    percentages.value.econBanPercentage = (
                        (stats.value.totalEconBans / stats.value.totalEntries) *
                        100
                    ).toFixed(2);
                    percentages.value.communityBanPercentage = (
                        (stats.value.totalCommunityBans / stats.value.totalEntries) *
                        100
                    ).toFixed(2);
                    percentages.value.gameBanPercentage = (
                        (stats.value.totalGameBans / stats.value.totalEntries) *
                        100
                    ).toFixed(2);
                }

                // Wait for the DOM update before accessing the canvas element
                await nextTick();
                if (banGraph.value) {
                    const ctx = banGraph.value.getContext("2d");
                    const monthlyData = calculateMonthlyBans(data.banDetails);
                    renderBanGraph(monthlyData, ctx);
                } else {
                    console.error("Canvas element not found");
                }
            } catch (err) {
                error.value = "Failed to fetch ban statistics.";
                console.error(err);
            } finally {
                loading.value = false;
            }
        };

        const sortTable = (key) => {
            if (sortKey.value === key) {
                sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
            } else {
                sortKey.value = key;
                sortDirection.value = "asc";
            }
            sortEntries(filteredEntries.value);
        };

        const sortEntries = (entries) => {
            const direction = sortDirection.value === "asc" ? 1 : -1;
            entries.sort((a, b) => {
                const aValue = a[sortKey.value] || "";
                const bValue = b[sortKey.value] || "";
                return aValue > bValue ? direction : aValue < bValue ? -direction : 0;
            });
        };

        const getSortIndicator = (key) => {
            if (sortKey.value === key) {
                return sortDirection.value === "asc" ? "↑" : "↓";
            }
            return "";
        };

        const filterTable = () => {
            const query = searchQuery.value.toLowerCase();
            const filtered = allEntries.value.filter((entry) =>
                entry.steamid.toLowerCase().includes(query)
            );
            sortEntries(filtered); // Sort after filtering
            filteredEntries.value = filtered;
            currentPage.value = 1; // Reset pagination
        };

        const paginatedEntries = computed(() => {
            const start = (currentPage.value - 1) * itemsPerPage;
            return filteredEntries.value.slice(start, start + itemsPerPage);
        });

        // Calculate monthly bans from estimatedBanDate
        const calculateMonthlyBans = (banDetails) => {
            const monthlyCounts = {};
            banDetails.forEach((entry) => {
                if (entry.estimatedBanDate) {
                    const date = new Date(entry.estimatedBanDate);
                    if (!isNaN(date)) {
                        const key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;
                        monthlyCounts[key] = (monthlyCounts[key] || 0) + 1;
                    }
                }
            });

            const sortedKeys = Object.keys(monthlyCounts).sort();
            return sortedKeys.map((key) => ({
                month: key,
                total: monthlyCounts[key],
            }));
        };

        // Render the line graph
        const renderBanGraph = (monthlyData, ctx) => {
            // Get the last 12 months
            const now = new Date();
            const last12Months = Array.from({ length: 12 }, (_, i) => {
                const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
                return {
                    month: date.toLocaleString("default", { month: "short" }).toUpperCase(), // Format as JAN, FEB, etc.
                    key: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`,
                };
            }).reverse();

            // Map the data to the last 12 months, filling gaps with 0
            const labels = last12Months.map((item) => item.month);
            const data = last12Months.map((item) => {
                const found = monthlyData.find((data) => data.month === item.key);
                return found ? found.total : 0; // Default to 0 if no data for the month
            });

            // Render the chart
            new Chart(ctx, {
                type: "line",
                data: {
                    labels, // Month labels
                    datasets: [
                        {
                            label: "VAC Bans",
                            data, // Monthly totals
                            borderColor: "rgba(75, 192, 192, 1)",
                            borderWidth: 1,
                            tension: 0.4, // Smooth the line
                            fill: false,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        title: {
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            type: "category",
                        },
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        };


        onMounted(fetchBanStats);

        return {
            stats,
            percentages,
            loading,
            error,
            searchQuery,
            filteredEntries,
            paginatedEntries,
            currentPage,
            totalPages: computed(() => Math.ceil(filteredEntries.value.length / itemsPerPage)),
            nextPage: () => {
                if (currentPage.value < Math.ceil(filteredEntries.value.length / itemsPerPage)) {
                    currentPage.value++;
                }
            },
            prevPage: () => {
                if (currentPage.value > 1) {
                    currentPage.value--;
                }
            },
            filterTable,
            sortTable,
            getSortIndicator,
            formatDate: (dateString) => {
                if (!dateString) return "N/A";
                const options = { day: "numeric", month: "long", year: "numeric" };
                return new Intl.DateTimeFormat("en-GB", options).format(new Date(dateString));
            },
            banGraph,
        };
    },
};
</script>
