<template>
    <div>
        <div v-if="loading" class="flex center h-70">
            <div class="spinner"></div>
        </div>
        <div v-else>
            <div class="border-bottom profile-detailed flex" :style="{
                backgroundImage: `url(${tournDetails.hero || defaultProfile.background})`,
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Overlay color with opacity
                backgroundBlendMode: 'overlay', // Blend the color with the image
                height: '25vh'
            }">
                <div class="profile-avatar" style="top:0">
                    <img :src="tournDetails.avatar || defaultProfile.avatar" />
                </div>
                <ul class="flex column" style="top:0">
                    <div class="flex column">
                        <li class="heading-text">{{ tournDetails.title }}</li>
                        <li class="subheading-text"><span class="tourn-detailed">{{ formatDateTime(tournDetails.date)
                                }}</span><span class="tourn-detailed">Prize: ${{ tournDetails.prizepool ?
                                formatPrizePool(tournDetails.prizepool) : 0 }}</span></li>
                    </div>
                </ul>
            </div>
            <div>
                <ul class="page-tab-menu flex">
                    <li v-for="tab in tabs" :key="tab" :class="{ 'tab-active': selectedTab === tab }"
                        @click="selectTab(tab)">
                        {{ tab }}
                    </li>
                </ul>
            </div>
            <div class="container">
                <div v-if="selectedTab === 'Overview'" class="flex">
                    <div class="flex column w-70">
                        <h2>Placeholder Subtitle</h2>
                        <p>Placeholder Desc</p>
                    </div>
                    <div class="flex w-30" style="height: 3em;">
                        <button v-if="isInTeam && !tournDetails.teams.includes(user.team)" @click="handleJoinTournament"
                            class="btn-20">Join
                            Tournament</button>
                        <button v-else-if="!user.team" @click="showCreateTeamModal" class="btn-20">Create Team</button>
                        <EditTeamModal v-if="showModal" :editableTeam="editableTeam" :isVisible="showModal"
                            @save="createTeam" @close="closeModal" />
                        <div v-else-if="isInTeam && tournDetails.teams.includes(user.team)">
                            <button class="btn-20 btn-disabled">Registered</button>
                        </div>
                    </div>
                </div>
                <div v-if="selectedTab === 'Bracket'">
                    <ul>
                        <li v-if="tournDetails.rounds == null || !tournDetails.rounds.length" class="no-item-message">
                            No Bracket Available
                        </li>
                        <li v-else>
                            <h1>{{ selectedTab }}</h1>
                            <div class="ladder">
                                
                                <div v-for="(round, roundIndex) in tournDetails.rounds" :key="roundIndex" class="round">
                                    <a v-for="(match, matchIndex) in round.matches" :key="matchIndex" class="match"
                                        :href="'/match/' + match.matchId">

                                        <div class="team flex">
                                            <span>{{ match.team1 && match.team1 ? match.team1 : "TBD"
                                                }}</span>
                                            <span>{{ match.team1 && match.team1Score !== undefined ? match.team1Score
                                                : 0 }}</span>
                                        </div>

                                        <div class="connector"></div>

                                        <div class="team flex">
                                            <span>{{ match.team2 && match.team2 ? match.team2 : "TBD"
                                                }}</span>
                                            <span>{{ match.team2 && match.team2Score !== undefined ? match.team2Score
                                                : 0 }}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="selectedTab === 'Teams'">
                    <ul class="flex">
                        <li v-if="!tournTeamProfiles.length" class="no-item-message">
                            No Team Profiles Available
                        </li>
                        <li v-else class="display-card flex column" v-for="team in tournTeamProfiles" :key="team.id"
                            @click="navigateToTournament(team._id)" role="button" tabindex="0" :style="{
                                backgroundImage: `url(${team.banner ? team.banner : defaultProfile.background})`,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
                                backgroundBlendMode: 'overlay' // Blend the color with the image
                            }">
                            <div class="display-card-header flex">
                                <h4>{{ team.name }}</h4>
                            </div>
                            <div class="display-card-body flex">
                                <p>{{ team.region }}</p>
                            </div>
                            <div class="display-card-body flex">
                                <ul>
                                    <li v-for="player in team.players" :key="player" class="tourn-player-card">
                                        <a v-if="player !==null" :href="'/Profile/' + player.steamId">{{
                                            player.displayName }}</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "../api";
import { mapState, mapActions } from "vuex";
import EditTeamModal from "@/components/editFieldModal.vue";

export default {
    name: "TournamentDetailed",
    components: {
        EditTeamModal,
    },
    data() {
        return {
            showModal: false,
            defaultProfile: {
                avatar: require('@/assets/svg/profiles/default/blankplayer.png'),
                banner: require('@/assets/svg/profiles/default/background.svg')
            },
            selectedTab: "Overview",
            tabs: ["Overview", "Bracket", "Teams", "Results", "Streams"],
            tournDetails: {},
            user: {},
            teams: [],
            tournTeamProfiles: [], // Initialized as an array
            loading: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        isInTeam() {
            return this.teams.some((team) => team.players.includes(this.user.steamId));
        },
    },
    methods: {
        ...mapActions(["fetchPlayerData"]),
        showCreateTeamModal() {
            this.editableTeam = {
                region: this.user.region || '',
            };
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        async createTeam(teamData) {
            try {
                await api.createTeam(teamData); // Removed 'result' assignment
                alert("Team created successfully!");
                this.fetchData(); // Refresh the tournament data
                this.closeModal();
            } catch (error) {
                alert(error.response?.data?.error || "Failed to create team");
            }
        },
        selectTab(tab) {
            this.selectedTab = tab;
        },
        formatPrizePool(amount) {
            return new Intl.NumberFormat().format(amount);
        },
        formatDateTime(date) {
            if (!date) return 'Date not available'; // Return placeholder if date is undefined or null

            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            };

            try {
                const formattedDate = new Intl.DateTimeFormat(navigator.language, options).format(new Date(date));
                return formattedDate.replace(' at ', ' '); // Ensure consistent formatting
            } catch (error) {
                console.error('Error formatting date:', error);
                return 'Invalid date'; // Return fallback in case of error
            }
        },
        navigateToTournament(teamId) {
            this.$router.push(`/team/${teamId}`);
        },
        async fetchData() {
            try {
                this.loading = true;

                const tournId = this.$route.params.tournament;

                const [teams, loginStatus, tournData] = await Promise.all([
                    api.getAllTeams(),
                    api.checkLoginStatus(),
                    api.getTournData(tournId),
                ]);

                this.teams = teams;
                this.user = await api.getPlayerSummaries(loginStatus.user.id);

                let rounds = [];
                if (Array.isArray(tournData.rounds)) {
                    rounds = await Promise.all(
                        tournData.rounds.map(async (round) => {
                            const hydratedMatches = await Promise.all(
                                round.matches.map(async (match) => {
                                    if (match.matchId) {
                                        try {
                                            const fullMatch = await api.getMatchDetails(match.matchId);
                                            return { ...match, ...fullMatch };
                                        } catch (err) {
                                            console.warn("Failed to fetch match:", match.matchId, err);
                                            return match;
                                        }
                                    }
                                    return match;
                                })
                            );
                            return { ...round, matches: hydratedMatches };
                        })
                    );
                }

                this.tournDetails = { ...tournData, rounds };

                const profiles = await Promise.all(
                    tournData.teams.map(async (teamId) => {
                        const teamProfile = await api.getTeamProfile(teamId);
                        const playerDetails = await Promise.all(
                            teamProfile.players.map((steamId) => api.getPlayerSummaries(steamId))
                        );
                        return { ...teamProfile, players: playerDetails };
                    })
                );

                this.tournTeamProfiles = profiles;
                this.loading = false;
            } catch (error) {
                console.error("Error fetching tournament data:", error);
                this.loading = false;
            }
        },
        async handleJoinTournament() {
            try {
                if (!this.user.team) {
                    return alert("You need to create a team first.");
                }

                const result = await api.joinTourn(this.tournDetails._id, this.user.team);
                alert(result.message);
                this.fetchData(); // Refresh data after joining
            } catch (error) {
                alert(error.response?.data?.error || "Failed to join tournament");
            }
        }
    },
    mounted() {
        this.fetchData();
    },
};
</script>
