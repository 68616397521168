<template>
  <div>
    <div v-if="loading" class="flex center h-70">
      <div class="spinner"></div>
    </div>
    <div v-else>
      <div class="sticky-header">
        <div>
          <ul class="page-tab-menu flex">
            <li :class="{ 'tab-active': selectedTab === 'Overview' }" @click="selectTab('Overview')">Overview</li>
            <li :class="{ 'tab-active': selectedTab === 'Stats' }" @click="selectTab('Stats')">Stats</li>
            <li :class="{ 'tab-active': selectedTab === 'Matches' }" @click="selectTab('Matches')">Matches</li>
            <li :class="{ 'tab-active': selectedTab === 'Tournaments' }" @click="selectTab('Tournaments')">Tournaments
            </li>
            <li :class="{ 'tab-active': selectedTab === 'PlayerVerse' }" @click="selectTab('PlayerVerse')">PlayerVerse
            </li>
          </ul>
        </div>
        <div class="border-bottom profile-detailed flex" :style="{
          backgroundImage: `url(${team.banner || defaultProfile.banner})`,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
          backgroundBlendMode: 'overlay', // Blend the color with the image
          height: '20vh'
        }">
          <button v-if="team.owner && loggedin?.user?.id === team.owner" @click="openModal" class="edit-btn flex">
            <p>Edit Team</p>
          </button>
          <button v-if="team.owner !== loggedin?.user?.id && team.players.includes(loggedin?.user?.id)"
            @click="removePlayer" class="edit-btn flex">
            <p>Leave Team</p>
          </button>
          <div class="profile-avatar flex column">
            <img :src="team.avatar !== null || team.avatar ? team.avatar : defaultProfile.avatar" />
            <input ref="fileInput" type="file" @change="handleFileUpload" class="file-input" />
          </div>
          <ul class="flex column team-name-container">
            <div class="flex column">
              <li class="heading-text">{{ team.name }}</li>
              <li class="subheading-text">{{ team.region }}</li>
            </div>
            <ul class="flex row social-badges">
              <li v-for="(social, index) in team.socials" :key="index">
                <a :href="social.link" target="_blank">
                  <img :src="getFavicon(social.link)" alt="social icon" />
                </a>
              </li>
            </ul>
          </ul>
          <div class="flex column profile-details">
            <div class="flex elo-container">
              <span class="nav-gamecoins flex">
                <img src="../assets/svg/qualipoints.svg" />
                <p style="font-weight: 900; font-style: italic;">{{ team.qualipoints ? team.qualipoints : 0 }}</p>
              </span>
              <span class="display-elo-banner">
                <p>CS2</p> <span style="font-weight: 900;" :style="{ color: formatedEloColor }">{{ formatedElo ?
                  formatedElo
                  : 0 }}</span>
              </span>
              <span class="display-elo-banner">
                <p>PlayerVerse Rating</p> <span style="font-weight: 900;">{{ team.elo ? team.elo : 0 }}</span>
              </span>
            </div>
            <div class="header-details ">
              <div class="table flex">
                <p class="heavy table-row flex">KD </p>
                <p class="heavy table-row flex">HS %</p>
                <p class="heavy table-row flex">Win %</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-stats flex column">
        <div class="page-tabs container">
          <div v-if="selectedTab === 'Stats'" class="statistics">
            <!-- Stats content goes here -->
          </div>
          <div v-if="selectedTab === 'Overview'">
            <!-- Overview content goes here -->
          </div>
          <div v-if="selectedTab === 'Matches'">
            <!-- Results content goes here -->
          </div>
          <div v-if="selectedTab === 'Tournaments'">
            <ul class="flex container">
              <li v-if="!teamTourns.length" class="no-item-message">
                No Team Tournaments
              </li>
              <li v-else class="display-card flex column" v-for="tourn in teamTourns" :key="tourn._id"
                @click="navigateToTournament(tourn._id)" role="button" tabindex="0" :style="{
                  backgroundImage: `url(${tourn.hero ? tourn.hero : defaultProfile.background})`,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color with opacity
                  backgroundBlendMode: 'overlay' // Blend the color with the image
                }">
                <div class="display-card-header flex">
                  <h4>{{ tourn.title }}</h4>
                </div>
                <div class="display-card-body flex">
                  <p>{{ formatDateTime(tourn.date) }}</p>
                </div>
                <div class="display-card-body flex">
                  <p>{{ tourn.region }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="selectedTab === 'PlayerVerse'">
            <p class="no-item-message">Coming Soon</p>
          </div>
        </div>
      </div>
      <edit-field-modal :isVisible="isModalVisible" :editableTeam="team" :defaultProfile="defaultProfile"
        @close="closeModal" @save="handleSave"></edit-field-modal>
    </div>
  </div>
</template>

<script>
import api from '@/api'; // Import the api.js module
import editFieldModal from "@/components/editFieldModal.vue";
import { useRouter } from 'vue-router';

export default {
  name: "TeamDetails",
  components: {
    editFieldModal
  },
  data() {
    return {
      selectedTab: "Overview",
      team: { name: "", avatar: "", socials: [], qualipoints: null },
      defaultProfile: {
        avatar: require('@/assets/svg/profiles/default/blankplayer.png'),
        banner: require('@/assets/svg/profiles/default/background.svg')
      },
      loggedin: null,
      isModalVisible: false,
      avatarFile: null,
      loading: true,
      teamTourns: [],
    };
  },
  setup() {
    const router = useRouter();
    return {
      router
    }
  },
  methods: {
    getFavicon(link) {
      try {
        const url = new URL(link);
        return `https://api.faviconkit.com/${url.hostname}/64`;
      } catch (error) {
        console.error("Invalid URL for favicon:", link);
        return null; // Return a fallback or null if the URL is invalid
      }
    },
    async fetchData() {
      const teamId = this.$route.params.teamid;
      try {
        this.team = await api.getTeamProfile(teamId); // Use api.js to fetch team profile
        this.loggedin = await api.checkLoginStatus(); // Use api.js to check login status
        this.teamTourns = await api.getTeamTourns(teamId);
        this.loading = false;
      } catch (error) {
        console.log("Error fetching team:", error);
      }
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    openModal() {
      this.isModalVisible = true;
    },
    removePlayer() {
      const friendId = this.loggedin.user.id;
      const teamId = this.team._id;
      api.kickTeamMate({friendId: friendId, teamId: teamId});
    },
    closeModal() {
      this.isModalVisible = false;
    },
    handleFileUpload(event) {
      this.avatarFile = event.target.files[0];
    },
    // Method to format the date
    formatDateTime(date) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

      // Get the formatted date and time
      let formattedDate = new Intl.DateTimeFormat(navigator.language, options).format(new Date(date));

      // Handle cases where 'at' might appear
      formattedDate = formattedDate.replace(' at ', ' ');
      return formattedDate;
    },
    navigateToTournament(tournId) {
      this.router.push(`/tournament/${tournId}`);
    },
    async handleSave(updatedTeam) {
      const formData = new FormData();
      formData.append('name', updatedTeam.name);
      formData.append('qualipoints', updatedTeam.qualipoints);
      formData.append('socials', JSON.stringify(updatedTeam.socials || []));
      if (this.avatarFile) {
        formData.append('avatar', this.avatarFile);
      }

      try {
        await api.updateTeamProfile(this.team._id, formData); // Use api.js to update team profile
        this.team = await api.getTeamProfile(this.team._id); // Refetch the updated team from the backend
        this.avatarFile = null; // Clear the avatar file after upload
        this.closeModal();
      } catch (error) {
        console.error("Error saving team edits:", error);
      }
    }
  },
  async mounted() {
    await this.fetchData();
  }
};
</script>
