<template>
  <div class="modal-overlay">
    <div class="modal modal-popup show">
      <h3 class="heavy">Match Found</h3>
      <div class="avatars">
        <div 
          v-for="(player, index) in 10" 
          :key="index" 
          class="avatar-wrapper"
          :class="{ 'greyed-out': index >= acceptedPlayers.length }"
        >
          <img 
            :src="require('@/assets/svg/profiles/default/blankplayer.png')" 
            class="avatar"
            :alt="'Player ' + (index + 1)"
          />
          <div class="overlay" v-if="index >= acceptedPlayers.length"></div>
        </div>
      </div>
      <button class="btn-match btn-accept" @click="accept">Accept</button>
      <button class="btn-match btn-close" @click="close">Close Modal</button>
      <p v-if="timer > 0" class="match-timer">{{ formattedTimer }}</p>
    </div>
  </div>
</template>

<script>
import { getSocket } from '@/socket';
export default {
  props: {
    matchId: String,
    acceptedCount: Number,
    userId: String
  },
  data() {
    return {
      timer: 15,
      acceptedPlayers: [],
      socket: null,
      matchSound: new Audio(require("@/assets/audio/game_found.mp3")),
    };
  },
  computed: {
    formattedTimer() {
      const minutes = Math.floor(this.timer / 60);
      const seconds = this.timer % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  mounted() {
    this.socket = getSocket();
    this.startTimer();
    this.updateAcceptedPlayers(this.acceptedCount);
    this.socket.on('timerUpdate', ({ matchId, remainingTime }) => {
      if (this.matchId === matchId) {
        this.timer = remainingTime;
      }
    });
    this.socket.on('acceptanceUpdate', ({ matchId, acceptedCount, acceptedPlayers }) => {
      if (this.matchId === matchId) {
        this.updateAcceptedPlayers(acceptedCount, acceptedPlayers);
      }
    });

    const playMatchSound = () => {
      this.matchSound.play();
    };

    playMatchSound();

  },
  unmounted() {
    if (this.socket) {
      this.socket.disconnect();
      }
  },
  methods: {
    accept() {
      this.socket.emit('acceptMatch', { userId: this.userId, matchId: this.matchId });
    },
    startTimer() {
      if (this.matchId) {
        this.socket.emit('getTimer', this.matchId);
      }
    },
    updateAcceptedPlayers(count, players = []) {
      this.acceptedPlayers = players.length ? players : Array.from({ length: count }, (_, i) => ({
        avatar: `/path/to/player/avatar${i + 1}.png` // Replace with actual logic to get player avatars
      }));
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.avatars {
  display: flex;
  justify-content: space-around;
  margin: 1em 0;
}
.avatar-wrapper {
  position: relative;
  width: 50px;
  height: 50px;
}
.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.greyed-out .avatar {
  opacity: 0.5;
}
.overlay {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  border-radius: 50%;
}
</style>
