<template>
  <div class="scoreboard-container">
    <h2 class="title">Final Match Scoreboard</h2>
    <div class="flex row" style="justify-content: space-evenly;font-weight: 900;">
      <div>{{ results.team1 }}</div>
      <div>{{ results.team1Score }}</div>
      <div>{{ results.team2Score }}</div>
      <div>{{ results.team2 }}</div>
    </div>

    <!-- Counter-Terrorists -->
    <div class="team-section ct-team">
      <h3 class="team-name">Counter-Terrorists</h3>
      <table class="scoreboard-table">
        <thead>
          <tr>
            <th>Avatar</th>
            <th>Nickname</th>
            <th>K</th>
            <th>A</th>
            <th>D</th>
            <th>MVPs</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="player in team1Players" :key="player.steam_id_64">
            <td><img :src="player.avatar" class="avatar" v-if="player.avatar" /></td>
            <td><a :href="'/Profile/' + player.steam_id_64">{{ player.displayName || "Loading..." }}</a></td>
            <td>{{ player.stats.kills }}</td>
            <td>{{ player.stats.assists }}</td>
            <td>{{ player.stats.deaths }}</td>
            <td>{{ player.stats.mvps }}</td>
            <td>{{ player.stats.score }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Terrorists -->
    <div class="team-section t-team">
      <h3 class="team-name">Terrorists</h3>
      <table class="scoreboard-table">
        <thead>
          <tr>
            <th>Avatar</th>
            <th>Nickname</th>
            <th>K</th>
            <th>A</th>
            <th>D</th>
            <th>MVPs</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="player in team2Players" :key="player.steam_id_64">
            <td><img :src="player.avatar" class="avatar" v-if="player.avatar" /></td>
            <td><a :href="'/Profile/' + player.steam_id_64">{{ player.displayName || "Loading..." }}</a></td>
            <td>{{ player.stats.kills }}</td>
            <td>{{ player.stats.assists }}</td>
            <td>{{ player.stats.deaths }}</td>
            <td>{{ player.stats.mvps }}</td>
            <td>{{ player.stats.score }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from "@/api"; // Ensure this is correctly imported

export default {
  props: {
    players: {
      type: Array,
      required: true
    },
    results: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      playerDetails: {},
      loading: true
    };
  },
  computed: {
    team1Players() {
      return this.players.filter(player => player.team === "team1").map(player => ({
        ...player,
        displayName: this.playerDetails[player.steam_id_64]?.displayName || "Loading...",
        avatar: this.playerDetails[player.steam_id_64]?.avatar || null
      }));
    },
    team2Players() {
      return this.players.filter(player => player.team === "team2").map(player => ({
        ...player,
        displayName: this.playerDetails[player.steam_id_64]?.displayName || "Loading...",
        avatar: this.playerDetails[player.steam_id_64]?.avatar || null
      }));
    }
  },
  async mounted() {
    await this.fetchPlayerDetails();
  },
  methods: {
    async fetchPlayerDetails() {
      this.loading = true;
      try {
        const steamIds = this.players.map(player => player.steam_id_64);
        const playerDataArray = await this.fetchPlayerSummaries(steamIds);

        const playerMap = {};
        playerDataArray.forEach(player => {
          playerMap[player.steamId] = {
            displayName: player.displayName,
            avatar: player.avatar
          };
        });

        this.playerDetails = playerMap;
      } catch (error) {
        console.error("Error fetching player details:", error);
      }
      this.loading = false;
    },
    async fetchPlayerSummaries(playerIds) {
      const playerDetailsPromises = playerIds.map(async (steamId) => {
        return await api.getPlayerSummaries(steamId);
      });
      return await Promise.all(playerDetailsPromises);
    }
  }
};
</script>

<style scoped>
.avatar {
  width: 3em;
  border-radius: 0.2em;
}

.scoreboard-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 3em;
  width: 80%;
  margin: auto;
  color: white;
}

.title {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.team-section {
  background: #2a2a2a;
  padding: 2em;
}

.team-name {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.scoreboard-table {
  width: 100%;
  border-collapse: collapse;
}

.scoreboard-table th,
.scoreboard-table td {
  padding: 1em;
  text-align: center;
  border-bottom: 1px solid gray;
  vertical-align: middle;
}

.ct-team {
  background: rgba(0, 150, 255, 0.2);
}

.t-team {
  background: rgba(255, 100, 50, 0.2);
}
</style>