<template>
    <div>
        <div class="sticky-header">
            <div>
                <ul class="page-tab-menu flex">
                    <li v-for="region in regions" :key="region" :class="{ 'tab-active': selectedTab === region }"
                        @click="selectTab(region)">
                        {{ region }}
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="loading === true" class="flex center h-70">
            <div class="spinner"></div>
        </div>
        <div v-else-if="loading === false" class="flex column">
            <div class="container wager-container">
                <div class="flex wager-btn-container">
                    <h3>Wager Matches</h3>
                    <button v-if="logged.region != selectedTab" class="wager-match-btn-disabled flex">Incorrect
                        Region</button>
                    <button v-else-if="isUserInMatch" class="wager-match-btn-disabled flex">Already Matched</button>
                    <button v-else @click="openPrizePoolModal" class="wager-match-btn flex">Create Match</button>

                </div>
                <div v-for="region in regions" :key="region">
                    <div v-if="selectedTab === region" class="flex column">
                        <ul class="flex table column wager-row">
                            <li class="flex wager-item" v-for="match in filteredWagerMatches" :key="match._id">
                                <div class="wager-players flex">
                                    <div v-for="(player, playerIndex) in fillPlayers(match._id)" :key="playerIndex"
                                        class="wager-player flex">
                                        <div v-if="player" class="avatar-container">
                                            <router-link class="flex" :to="`/Profile/${player.steamId}`">
                                                <img :src="player.avatar" class="mm-banner-avatar" />
                                            </router-link>
                                            <button v-if="logged.id == player.steamId"
                                                class="header-remove-btn flex center"
                                                @click="removeMember(logged.id)">X</button>
                                        </div>
                                        <div v-else class="flex">
                                            <button v-if="logged.region === selectedTab && !isUserInMatch"
                                                @click="joinMatch(match._id, $event)" class="wager-btn">+</button>
                                            <button v-else class="wager-btn-disabled">x</button>
                                            <transition name="fade">
                                                <div v-if="errorMatchId === match._id" class="error-bubble">
                                                    You're already in this match
                                                </div>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="wager-prizepool flex">
                                    <img :src='require("@/assets/svg/gamecoins.svg")' />
                                    <h4>{{ match.prizePool ? formatPrizePool(match.prizePool) : 0 }}</h4>
                                </div>
                            </li>
                            <li v-if="filteredWagerMatches.length === 0" class="no-item-message">
                                No current matches
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <prize-pool-modal :isVisible="isPrizePoolModalVisible" @close="isPrizePoolModalVisible = false" @submit="handlePrizePoolSubmit" />
    </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue';
import PrizePoolModal from './CreateWager.vue'; // Adjust the path as needed
import api from "../api";
import { getSocket } from '@/socket';
export default {
    name: "WagerMatches",
    components: {
    PrizePoolModal
  },
    setup() {
        const selectedTab = ref("Oceania");
        const regions = ref(["Oceania", "North America", "South America", "Europe", "Asia", "Africa"]);
        const loading = ref(true);
        const wagerMatches = ref([]);
        const matchPlayers = reactive({});
        const errorMatchId = ref(null);
        const logged = ref({});
        const prizePoolAmount = ref(0);
        const isPrizePoolModalVisible = ref(false);
        const socket = ref(null);

        const fetchData = async () => {
            try {
                loading.value = true;

                const [matches, loggedInUser] = await Promise.all([
                    api.getWagerMatches(),
                    api.checkLoginStatus()
                ]);

                const loggedInUserSummary = await api.getPlayerSummaries(loggedInUser.user.id);

                // Assign the user and the region from the summary
                logged.value = loggedInUser.user;
                logged.value.region = loggedInUserSummary.region;
                wagerMatches.value = matches.filter(match => match.region);

                await Promise.all(
                    wagerMatches.value.map(async (match) => {
                        const players = await Promise.all(
                            match.pendingUsers.map(playerId => api.getPlayerSummaries(playerId))
                        );
                        matchPlayers[match._id] = players;
                    })
                );

                loading.value = false;
            } catch (error) {
                console.error("Error fetching data:", error);
                loading.value = false;
            }
        };

        const handlePrizePoolSubmit = (amount) => {
    prizePoolAmount.value = amount;
    createMatch(); // Call createMatch with the prize pool amount set
};

const openPrizePoolModal = () => {
    isPrizePoolModalVisible.value = true;
};

        const createMatch = async () => {
    try {
        const response = await api.createMatch({
            team1: "Challengers", // Example team names
            team2: "Defenders",
            prizePool: prizePoolAmount.value, // Use the prize pool amount from the modal
            region: selectedTab.value // Use the selected region
        });

        if (response.success) {
            await fetchData(); // Refresh data after match creation
        } else {
            console.error("Failed to create match:", response.error);
        }
    } catch (error) {
        console.error("Error creating match:", error);
    }
};


        const removeMember = async (steamId) => {
            try {
                const match = wagerMatches.value.find(m => m.pendingUsers.includes(steamId));
                if (match) {
                    await api.removePlayerFromMatch(match._id, steamId);
                    await fetchData(); // Refresh data after removal
                }
            } catch (error) {
                console.error("Error removing member:", error);
            }
        };

        const fillPlayers = (matchId) => {
            const maxPlayers = 10;
            const players = matchPlayers[matchId] || [];
            const filledPlayers = [...players];
            while (filledPlayers.length < maxPlayers) {
                filledPlayers.push(null);
            }
            return filledPlayers;
        };

        const formatPrizePool = (amount) => {
            return new Intl.NumberFormat().format(amount);
        };

        const selectTab = (region) => {
            selectedTab.value = region;
        };

        const joinMatch = async (matchId) => {
            const socket = getSocket();
            try {
                const user = await api.checkLoginStatus();
                const match = wagerMatches.value.find(m => m._id === matchId);
                
                if (match && match.pendingUsers.includes(user.user.id)) {
                    // Show error message
                    errorMatchId.value = matchId;
                    setTimeout(() => {
                        errorMatchId.value = null;
                    }, 3000);
                } else {
                    await api.acceptMatch(matchId);
                    socket.on("joinMatchRoom", (matchId) => {
                        socket.join(`match-${matchId}`);
                    });
                    await fetchData();
                }
            } catch (error) {
                console.error("Error joining match:", error);
            }
        };

        const filteredWagerMatches = computed(() => {
            return wagerMatches.value.filter(match => match.region === selectedTab.value);
        });

        const isUserInMatch = computed(() => {
            return wagerMatches.value.some(match => match.pendingUsers.includes(logged.value.id));
        });

        onMounted(() => {
            fetchData();
            socket.value = getSocket();
            // Listen for socket.value.io events to update the UI
            socket.value.on('playerRemoved', () => {
                fetchData(); // Fetch the latest data when a player is removed
            });

            socket.value.on('matchDeleted', () => {
                fetchData(); // Fetch the latest data when a match is deleted
            });

            socket.value.on('matchCreated', () => {
                fetchData(); // Fetch the latest data when a match is deleted
            });

        });

        return {
            selectedTab,
            regions,
            loading,
            wagerMatches,
            matchPlayers,
            errorMatchId,
            fillPlayers,
            formatPrizePool,
            selectTab,
            joinMatch,
            filteredWagerMatches,
            logged,
            removeMember, // Return removeMember to make it available in the template
            isUserInMatch,
            openPrizePoolModal,
            handlePrizePoolSubmit,
            createMatch,
            prizePoolAmount,
            isPrizePoolModalVisible,
            socket
        };
    }
};

</script>

<style>
.error-bubble {
    position: absolute;
    top: -3em;
    left: 50%;
    transform: translateX(-50%);
    background-color: red;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 10;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>
