<template>
  <div class="modal-overlay" v-if="isVisible">
    <div class="modal modal-popup show column flex">
      <h3 style="margin-bottom: 1em">Edit Team Details</h3>
      <div class="flex row modal-links" style="margin-bottom: 1.5em">
        <div class="flex w-100">
          <div class="banner-container profile-banner">
            <img :src="bannerPreview || (localEditableTeam.banner ? localEditableTeam.banner : defaultProfile.banner)"
              alt="Banner" />
            <div class="edit-overlay" @click="triggerFileInput('banner')">
              <a class="edit-button">
                <img src="@/assets/svg/edit.svg" />
              </a>
            </div>
          </div>
          <input style="display: none;" id="team-banner" ref="bannerInput" type="file"
            @change="handleFileUpload('banner')" />
        </div>
      </div>
      <div class="flex row modal-links">
        <div class="flex">
          <div class="avatar-container profile-avatar">
            <img :src="avatarPreview || (localEditableTeam.avatar ? localEditableTeam.avatar : defaultProfile.avatar)"
              alt="Avatar" />
            <div class="edit-overlay" @click="triggerFileInput('avatar')">
              <a class="edit-button">
                <img src="@/assets/svg/edit.svg" />
              </a>
            </div>
          </div>
          <input style="display: none;" id="team-avatar" ref="avatarInput" type="file"
            @change="handleFileUpload('avatar')" />
        </div>
        <div class="modal-content-fill" style="margin-left: 2em">
          <div>
            <h2 for="team-name">Team Name</h2>
          </div>
          <div>
            <input class="link-item" id="team-name" v-model="localEditableTeam.name" type="text"
              placeholder="Enter team name" />
          </div>
        </div>
      </div>
      <div class="flex column modal-links">
        <div>
          <h2 class="link-title-marg" for="team-links">Team Links</h2>
        </div>
        <div class="flex link-item" v-for="(link, index) in localEditableTeam.socials" :key="index">
          <input v-model="link.link" type="text" placeholder="Enter link" />
          <button class="reject-btn flex" @click="removeLink(index)">
            <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 63.09 63.09">
              <polygon class="reject-btn-svg"
                points="63.09 13.28 49.81 0 31.55 18.26 13.28 0 0 13.28 18.26 31.55 0 49.81 13.28 63.09 31.55 44.83 49.81 63.09 63.09 49.81 44.83 31.55 63.09 13.28" />
            </svg>
          </button>
        </div>
        <div class="flex center">
          <button class="link-add flex" @click="addLink"
            v-if="localEditableTeam.socials ? localEditableTeam.socials.length < 5 : 'Add Link'">+</button>
        </div>
      </div>
      <div class="flex column modal-links">
        <div>
          <h2 class="link-title-marg" style="margin-top:0.5em">Invite Teammates</h2>
        </div>
        <!-- Search Bar -->
        <div class="flex link-item">
          <input type="text" v-model="searchQuery" placeholder="Search Friends" @input="searchFriends" />
        </div>
        <!-- Friends List with Invite Button -->
        <ul class="flex column">
          <li v-for="friend in filteredFriends" :key="friend.friendId" class="friend-item chat-friend">
            <div class="friend-info flex">
              <img :src="friend.avatar" alt="avatar" class="chat-friend-avatar">
              <span>{{ friend.displayName }}</span>
              <span v-if="invitationStatus[friend.friendId] === 'sent'" class="invited-overlay">Invited</span>
              <button class="reject-btn flex" v-else-if="invitationStatus[friend.friendId] === 'in_team'"
                @click="kickTeamMate(friend)">
                <svg height="100%" width="100%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 63.09 63.09">
                  <polygon class="reject-btn-svg"
                    points="63.09 13.28 49.81 0 31.55 18.26 13.28 0 0 13.28 18.26 31.55 0 49.81 13.28 63.09 31.55 44.83 49.81 63.09 63.09 49.81 44.83 31.55 63.09 13.28" />
                </svg>
              </button>
              <button v-else-if="invitationStatus[friend.friendId] === 'not_sent'" class="invite-btn flex"
                @click="inviteTeammate(friend)">
                Invite
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div class="flex center edit-controls">
        <button @click="saveEdits">Save</button>
        <button @click="closeModal">Cancel</button>
      </div>
      <div class="flex center edit-controls">
        <button class="btn-danger" @click="deleteTeam">Delete Team</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import api from "../api";

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    editableTeam: {
      type: Object,
      default: () => ({
        name: '',
        avatar: require('@/assets/svg/profiles/default/blankplayer.png'),
        banner: require('@/assets/svg/profiles/default/background.svg'),
        socials: [],
      }),
    },
    defaultProfile: {
      type: Object,
      default: () => ({
        avatar: require('@/assets/svg/profiles/default/blankplayer.png'),
        banner: require('@/assets/svg/profiles/default/background.svg')
      }),
    },
  },
  setup(props, { emit }) {
    const localEditableTeam = ref({ ...props.editableTeam });
    const avatarPreview = ref(null); // For previewing the selected avatar
    const bannerPreview = ref(null); // For previewing the selected banner
    const searchQuery = ref('');
    const friends = ref([]);
    const filteredFriends = ref([]);
    const invitationStatus = ref({});

    // Watch for changes in the editableTeam prop to update the localEditableTeam
    watch(() => props.editableTeam, (newVal) => {
      localEditableTeam.value = { ...newVal };
    });

    // Fetch friends and invitation statuses
    const fetchFriends = async () => {
      try {
        // Step 1: Fetch the list of friends
        const friendsList = await api.getFriends();
        friends.value = friendsList;

        // Step 2: Fetch the team profile to get the players array
        const teamProfile = await api.getTeamProfile(props.editableTeam._id);
        const teamPlayers = teamProfile.players || []; // Assume players is an array of steamIds
        console.log(teamProfile);

        // Step 3: Get current team invitations
        const teamInvites = await api.getTeamInvite(props.editableTeam._id);

        // Step 4: Iterate over each friend and set the appropriate status
        friendsList.forEach((friend) => {
          const { friendId } = friend;

          // Check if the friend is already in the team
          if (teamPlayers.includes(friendId)) {
            invitationStatus.value[friendId] = 'in_team';
          }
          // Check if an invite has already been sent
          else if (teamInvites.some((invite) => invite.friendId === friendId && invite.status === 'pending')) {
            invitationStatus.value[friendId] = 'sent';
          }
          // If not in team and no invite sent
          else {
            invitationStatus.value[friendId] = 'not_sent';
          }
        });
      } catch (error) {
        console.error('Error fetching friends list:', error);
      }
    };


    // Search for friends based on search query
    const searchFriends = () => {
      const query = searchQuery.value.toLowerCase();
      filteredFriends.value = friends.value.filter((friend) =>
        friend.displayName.toLowerCase().includes(query)
      );
    };

    // Invite a teammate
    const inviteTeammate = async (friend) => {
      try {
        await api.sendTeamInvite({ friendId: friend.friendId, teamId: props.editableTeam._id });
        invitationStatus.value[friend.friendId] = 'sent'; // Update the status
      } catch (error) {
        console.error('Error sending team invite:', error);
      }
    };

    const kickTeamMate = async (friend) => {
      try {
        await api.kickTeamMate({ friendId: friend.friendId, teamId: props.editableTeam._id });
        invitationStatus.value[friend.friendId] = 'not_sent'; // Update the status
      } catch (error) {
        console.error('Error kicking team invite:', error);
      }
    };

    const triggerFileInput = (type) => {
      if (type === 'avatar') {
        document.getElementById('team-avatar').click();
      } else if (type === 'banner') {
        document.getElementById('team-banner').click();
      }
    };

    const handleFileUpload = async (type) => {
      const file = type === 'avatar' ? document.getElementById('team-avatar').files[0] : document.getElementById('team-banner').files[0];
      if (!file) return;

      // Create a preview URL for the selected image
      if (type === 'avatar') {
        avatarPreview.value = URL.createObjectURL(file);
      } else if (type === 'banner') {
        bannerPreview.value = URL.createObjectURL(file);
      }

      try {
        const formData = new FormData();
        formData.append("file", file);  // Use "file" as the key name
        formData.append("teamId", localEditableTeam.value._id);

        const response = type === 'avatar' ? await api.uploadTeamAvatar(formData) : await api.uploadTeamBanner(formData);
        if (response.data && response.data.url) {
          // Update the local state with the new avatar or banner URL
          if (type === 'avatar') {
            localEditableTeam.value.avatar = response.data.url;
            avatarPreview.value = response.data.url; // Update the preview with the new URL from the server
          } else if (type === 'banner') {
            localEditableTeam.value.banner = response.data.url;
            bannerPreview.value = response.data.url; // Update the preview with the new URL from the server
          }
        } else {
          console.error("No URL returned in response:", response.data);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };


    const addLink = () => {
      if (!localEditableTeam.value.socials) {
        localEditableTeam.value.socials = [];
      }
      if (localEditableTeam.value.socials.length < 5) {
        localEditableTeam.value.socials.push({ link: '' });
      }
    };

    const removeLink = (index) => {
      if (localEditableTeam.value.socials && localEditableTeam.value.socials.length > index) {
        localEditableTeam.value.socials.splice(index, 1);
      }
    };

    const saveEdits = () => {
      emit('save', localEditableTeam.value);
      emit('close');
      window.location.reload();
    };

    const deleteTeam = async () => {
      if (confirm("Are you sure you want to delete this team? This action cannot be undone.")) {
        try {
          const result = await api.deleteTeam(localEditableTeam.value._id);
          if (result.success) {
            console.log("Team deleted successfully");
            window.location.href = 'https://playerverse.gg/ladders';
          }
        } catch (error) {
          console.error("Failed to delete team", error);
        }
      }
    };

    const closeModal = () => {
      emit('close');
    };

    fetchFriends();

    return {
      localEditableTeam,
      avatarPreview,
      bannerPreview,
      triggerFileInput,
      handleFileUpload,
      saveEdits,
      deleteTeam,
      closeModal,
      addLink,
      removeLink,
      searchQuery,
      filteredFriends,
      invitationStatus,
      searchFriends,
      inviteTeammate,
      kickTeamMate
    };
  }
};
</script>

<style scoped>
.profile-avatar {
  top: 0;
}

.modal {
  height: 60vh;
  width: 100vh;
  margin-left: 30vh;
  overflow: auto;
}
</style>
